





























































import Vue from 'vue';
import _ from 'lodash';
import { ElForm } from 'element-ui/types/form';
import { $cookies, $axios, $store } from '~/util/api';
import { ResponseCode } from '~/util/constant';
import slider from '../../../components/sliderImage/index.vue';

export default Vue.extend<any, any, any, any>({
  components: {
    slider,
  },
  data() {
    const validateUser = (
      _rule: any,
      value: any,
      cb: (msg?: string) => void
    ) => {
      if (!value) {
        this.$data.disabled = true;
        cb('请输入你的手机号或邮箱');
        return;
      }
      // if (!/^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$|(^1[6|7|9|3|4|5|8]\d{9}$)/.test(value)) {
      if (
        !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$|(^1[6|7|9|3|4|5|8]\d{9}$)/.test(
          value
        )
      ) {
        this.$data.disabled = true;
        cb('请输入正确的手机号或邮箱');
        return;
      }
      cb();
      this.$data.disabled = false;
      // const res: any = await $axios.get(`/api/user/checkExist/${value}`);
      // if (res.code === ResponseCode.SUCCESS) {
      //   if (res.data.isExist === 0) {
      //     this.$data.disabled = false;
      //     cb('该手机号未注册');
      //   } else {
      //     this.$data.disabled = false;
      //   }
      // } else {
      //   this.$data.disabled = false;
      // }
    };

    const validateRead = (
      _rule: any,
      value: any,
      cb: (msg?: string) => void
    ) => {
      if (!value) {
        cb('请阅读并勾选服务协议');
      } else {
        cb();
      }
    };

    return {
      isEdit: false,
      btnText: '获取验证码',
      disabled: true,
      loading: false,
      loginForm: {
        phoneNumber: '',
        code: '',
        read: false,
      },
      rules: {
        phoneNumber: [{ validator: validateUser, trigger: 'change' }],
        code: [
          { required: true, message: '请输入你的验证码', trigger: 'blur' },
        ],
        read: [
          {
            validator: validateRead,
            message: '请阅读并勾选服务协议',
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    handleSendCode() {
      const $loginForm = this.$refs.loginForm as ElForm;
      $loginForm.validateField('phoneNumber', async (msg) => {
        if (msg) {
          // 校验失败
          this.$message({
            message: msg,
            type: 'error',
          });
          return;
        }
        // const res: any = await $axios.get(`/api/blackIp/exists`);
        // if (res.code === ResponseCode.SUCCESS) {
        //   this.sentCode();
        // } else {

        // }
        const $sliderImage = this.$refs.sliderImage as ElForm;
        ($sliderImage as any).getDate();
      });
    },
    getCode(id: number) {
      this.sentCode(id);
    },
    async sentCode(id: number) {
      this.$data.loading = true;
      const { phoneNumber } = this.loginForm;
      const verCode = id;
      const res: any = await $axios.get(
        `/api/auth/sendRegisterVerifyCode/${phoneNumber}/${verCode}`
      );
      this.$data.loading = false;
      if (res.code === ResponseCode.SUCCESS) {
        let s = 60;
        this.disabled = true;
        const timer = setInterval(() => {
          if (s > 0) {
            this.btnText = `${s}秒后获取`;
            s--;
          } else if (s === 0) {
            this.btnText = '获取验证码';
            this.disabled = false;
            clearInterval(timer); // 清除定时器
          }
        }, 1000);
      }
    },
    handleLogin() {
      const $loginForm = this.$refs.loginForm as ElForm;
      $loginForm.validate(async (valid, err) => {
        if (!valid) {
          // 校验失败
          const msg_p = _.get(err, 'phoneNumber[0].message', '');
          const msg_s = _.get(err, 'code[0].message', '');
          const msg_3 = _.get(err, 'read[0].message', '');
          this.$message({
            message: msg_p || msg_s || msg_3 || '参数不合法',
            type: 'error',
          });
          return;
        }
        const { phoneNumber, code } = this.loginForm;
        const promotionLink = window.location.href;
        const { origins = 0, action = 0, industryId = 0 } = $store.state.user;
        const res: any = await $axios({
          url: `/api/auth/phonelr/${phoneNumber}/${code}`,
          method: 'GET',
          headers: { origins, action, industryId, promotionLink },
        });

        if (res.code === ResponseCode.SUCCESS) {
          const access_token = _.get(res, 'data.jwtToken.access_token', '');
          // const expires = _.get(res, 'data.jwtToken.expires_in', 0);
          let cookieObj: any = {
            maxAge: 60 * 60 * 24 * 7,
            path: '/',
            // domain: 'jufair.com',
          };
          if (process.env.NODE_ENV !== 'development') {
            // 非开发环境时 设置一级域名cookie
            cookieObj['domain'] = 'jufair.com';
          }

          $cookies.set('phoneNumber', phoneNumber, cookieObj);
          $cookies.set('access_token', access_token, cookieObj);

          let redirect = _.get(this, '$route.query.redirect');
          if (redirect) {
            redirect = redirect;
          } else {
            if (window.location.pathname == '/login') redirect = '/home';
          }

          // 初始化
          await $store.commit('user/setOrigins', '');
          await $store.commit('user/setIndustryId', 0);
          await $store.commit('user/setAction', 0);
          await $store.commit('global/setIsLogin', true);
          await $store.dispatch('global/getUser');
          this.$login.hide();

          //   if (!$store.state.global.user.isPererffect) {
          //     try {
          //       (this.$parent.$parent as any).showInfo();
          //     } catch (e) {
          //       (this.$parent.$parent.$parent as any).showInfo();
          //     }
          //   } else if (redirect) {
          //     // location.href = redirect;
          //     window.location.replace(decodeURIComponent(redirect));
          //   }
          if (redirect) window.location.replace(decodeURIComponent(redirect));
        }
      });
    },
  },
});
